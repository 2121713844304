<template>
    <div>
      <!-- Table Container Card -->
      <!-- Disabled add/remove hotel confirm modal -->
      <confirm-disable
        ref="disableModal"
        :msg="disableMsg"
        @on-submit="addRemoveDisabled"
        @on-hide="resetDisabledStatus"
      />
      <confirm-delete
        ref="deleteModal"
        :msg="deleteMsg"
        :title="titleMsg"
        @on-submit="deleteHotel"
      />
  
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Select Supplier Code"
                label-for="mc-select-supplier-code"
              >
                <v-select
                  v-model="supplierCode"
                  :options="SupplierCodes"
                  class="supplierCode"
                  placeholder="Select Supplier Code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="(val) => val.value"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>
  
            <b-col cols="12" md="6">
              <b-form-group label="Select Status" label-for="mc-select-status">
                <v-select
                  v-model="isDisabled"
                  :options="statusFilter"
                  class="mc-select-status"
                  placeholder="Select Status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>
  
            <!-- Search Hotel Id -->
            <b-col cols="12" md="6">
              <b-form-group
                label="Search Hotel ID"
                label-for="mc-search-hotel-ID"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="mc-search-hotel-ID"
                      v-model="searchHotelId"
                      placeholder="Search Hotel ID"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </b-col>
  
            <!-- Search Hotel Name -->
            <b-col cols="12" md="6">
              <b-form-group
                label="Search Hotel Name"
                label-for="mc-search-hotel-name"
              >
              <div class="d-flex align-items-center justify-content-end">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="mc-search-hotel-name"
                      v-model="searchHotelName"
                      placeholder="Search Hotel Name"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </b-col>
  
          </b-row>
        </div>
      </b-card>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" lg="6" sm="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" >
              <label>Show {{ selected }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <b-col cols="12" md="6" lg="6" sm="4">
              <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                <b-button
                v-if="checkLoginRole() === RoleEnum.SuperAdmin ? true : checkAccess.Add"
                variant="primary"
                @click="$router.push('add')"
              >
                <span class="text-nowrap">Add Hotel</span>
              </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          v-if="!contentLoading"
          ref="refContentListTable"
          class="position-relative"
          :items="items"
          responsive
          :search-options="{
            enabled: true,
            externalQuery: searchHotelId,
          }"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
  
          <template #cell(hotel_supplier_id)="data">
            {{ data.item && data.item.hotelSupplierId ? data.item.hotelSupplierId : '-' }}
          </template>

          <template #cell(hotel_name)="data">
            {{ data.item && data.item.name ? data.item.name : '-' }}
          </template>

          <template #cell(supplier_code)="data">
            {{ data.item && data.item.supplierCode ? data.item.supplierCode : '-' }}
          </template>
  
          <template #cell(resort)="data">
            {{ data.item && data.item.resort ? data.item.resort : '-' }}
          </template>

          <template #cell(is_disabled)="data">
            <div>
              <b-form-checkbox
                v-model="data.item.isDisabled"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="
                  openDisableModal(
                    data.item.id,
                    data.item.name,
                    data.item.isDisabled
                  )
                "
              >
                <span class="switch-icon-left">
                  <feather-icon icon="TrendingUpIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="DiscIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />  
              </template>
              <b-dropdown-item
                :to="{
                  name: 'disabled-hotel-edit',
                  params: { id: data.item.id },
                  query: { page: currentPage, rows: perPage },
                }"
                :disabled="
                  checkLoginRole() !== RoleEnum.SuperAdmin && !checkAccess.Edit
                "
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  checkLoginRole() === RoleEnum.SuperAdmin
                    ? true
                    : checkAccess.delete
                "
                @click="openModal(data.item.id, data.item.name)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="contentLoading">
          <Loader />
        </div>
  
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ totalHotel >= 1 ? startIndex + 1 : startIndex }} to
                {{ endIndex > totalHotel ? totalHotel : endIndex }} of
                {{ totalHotel }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalHotel"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BBadge,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import {
    perPageOptions,
    perPage,
    currentPage,
    resConditionCheck,
    RoleEnum,
    statusFilter,
    resolveUserStatusVariant,
    resolveUserStatusName,
    deleteConfirmMsg
  } from "@/helpers/constant";
  import errorResponseHandler from "@/services/errorHandler";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import checkLoginRole from "@/helpers/checkLoginRole";
  import accessRightCheck from "@/helpers/accessRightCheck";
  import { VueAutosuggest } from "vue-autosuggest";
  import Loader from "@/layouts/skeloton-loader/Loader.vue";
  import HotelContentService from "../../../services/hotel-content/hotelContent.service";
  import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
  import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BInputGroup,
      BTable,
      BDropdown,
      BDropdownItem,
      BPagination,
      BInputGroupPrepend,
      vSelect,
      VueAutosuggest,
      BFormGroup,
      Loader,
      BBadge,
      ConfirmDisable,
      ConfirmDelete,
      BButton,
      BFormCheckbox,
    },
  
    data() {
      return {
        contentLoading: true,
        searchHotelId: "",
        sortBy: "",
        perPageOptions,
        perPage,
        isSortDirDesc: false,
        currentPage,
        items: [],
        deleteMsg: "",
        RoleEnum,
        startIndex: 0,
        endIndex: 0,
        errorData: "",
        totalHotel: 0,
        checkLoginRole,
        checkAccess: {},
        noResults: false,
        tableColumns: [
        { key: "hotel_supplier_id" },
        { key: "hotel_name", sortable: true },
        { key: "supplier_code", sortable: true },
        { key: "resort", sortable: true },
        { key: "is_disabled", label: "STATUS" },
        { key: "action" },
        ],
        searchHotelName: "",
        results: [],
        timeout: null,
        selected: null,
        // citySelected: null,
        debounceMilliseconds: 250,
        supplierCode: "",
        SupplierCodes: [],
        isDisabled: "", // [ 0 = false, 1 = true ]
        statusFilter,
        resolveUserStatusVariant,
        resolveUserStatusName,
        status2: "",
        disableMsg: "",
        titleMsg: "",
        name: "",
        hotelSupplierId: "",
        hotelId: ""
      };
    },
  
    watch: {
      currentPage: {
        handler(val) {
          this.getDisabledHotelListing();
          this.$router
            .push({
              name: "disabled-hotel-list",
              query: { page: val, rows: this.perPage },
            })
            .catch(() => {});
        },
      },
  
      perPage: {
        handler(val) {
          // this.currentPage = 1;
          this.getDisabledHotelListing();
          this.$router
            .push({
              name: "disabled-hotel-list",
              query: { page: this.currentPage, rows: val },
            })
            .catch(() => {});
        },
      },
  
      sortBy: {
        handler() {
          this.getDisabledHotelListing();
        },
      },

      isSortDirDesc: {
        handler() {
          this.getDisabledHotelListing();
        },
      },
  
      // need to check
      supplierCode: {
        handler(val) {
          console.log(val)
          this.currentPage = 1;
          this.getDisabledHotelListing();
        },
      },
      
      isDisabled: {
        handler(val) {
          this.currentPage = 1;
          this.getDisabledHotelListing();
        },
      },
      
      searchHotelId: {
        handler() {
          this.getDisabledHotelListing();
        },
      },

      searchHotelName: {
        handler() {
          this.getDisabledHotelListing();
        }
      },
    },
  
    async mounted() {
      this.checkAccess = accessRightCheck(this.$route.meta.module.name);
      // if (this.checkLoginRole() !== RoleEnum.SuperAdmin && !this.checkAccess.Edit) {
      this.tableColumns = [
        { key: "hotel_supplier_id" },
        { key: "hotel_name", sortable: true },
        { key: "supplier_code", sortable: true },
        { key: "resort", sortable: true },
        { key: "is_disabled", label: "STATUS" },
        { key: "action" },
      ];
  
      await this.getDisabledHotelListing();
      this.getSupplierCodeDropdown();
      if (this.$route.query) {
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
        this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
      }
    },
  
    methods: {
      async getDisabledHotelListing() {
        this.contentLoading = true;
        const payload = {
          page: this.currentPage,
          no_of_result: this.perPage,
          order: this.isSortDirDesc === true ? "DESC" : "ASC",
          order_by: this.sortBy,
          hotel_supplier_id: this.searchHotelId,
          name: this.searchHotelName,
          is_disabled: this.isDisabled !== null || this.isDisabled !== '' ? this.isDisabled : '',
          supplier_code: this.supplierCode
        };
        console.log("payload", payload)
        await HotelContentService.listDisabledHotels(payload)
          .then((res) => {
            if (resConditionCheck(res.status) && res.data.data) {
              console.log("data", res.data.data[0])
              this.contentLoading = false;
              this.items = res.data.data[0];
              this.totalHotel = res.data.data[1];
              this.startIndex = this.currentPage * this.perPage - this.perPage;
              this.endIndex = this.startIndex + this.perPage;
            }
          })
          .catch((error) => {
            const errorData = errorResponseHandler(error);
            this.contentLoading = false;
            if (error.response.status === 404) {
              this.items = [];
              this.totalHotel = 0;
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      },
  
      /// need to check
      async getSupplierCodeDropdown() {
        try {
          this.items.forEach(item=>{
            if(!this.SupplierCodes.includes(item.supplierCode)) {
              this.SupplierCodes.push(item.supplierCode)
            }
          })
          this.SupplierCodes = this.SupplierCodes.map(e =>{
            return {
                label: e.toUpperCase(),
                value: e
            }
          })
        } catch (error) {
          console.log("error", error)
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      },
  
      openDisableModal(id, name, status) {
        this.hotelId = id;
        this.status2 = !status;
        this.$refs.disableModal.show();
        this.disableMsg = !status
          ? `Are you sure you want to enable <strong class="text-primary">${name}</strong> hotel ?`
          : `Are you sure you want to disable <strong class="text-primary">${name}</strong> hotel ?`;
      },
      resetDisabledStatus() {
        const foundIdex = this.items.findIndex(
          (item) => item.id === this.hotelId
        );
        this.$set(this.items[foundIdex], "isDisabled", this.status2);
      },
  
      addRemoveDisabled(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.disableModal.loading = true;
        if (this.hotelId) {
          HotelContentService.switchDisableStatus(this.hotelId)
            .then((res) => {
              this.$refs.disableModal.loading = false;
              this.$refs.disableModal.toggleModal = false;
              if (resConditionCheck(res.status)) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((error) => {
              this.$refs.disableModal.loading = false;
              this.$refs.disableModal.toggleModal = false;
              const errorData = errorResponseHandler(error);
              this.resetDisabledStatus();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      },

      openModal(id, name) {
        this.titleMsg = deleteConfirmMsg;
        this.deleteMsg = `Are you sure you want to delete ${name} disabled hotel ?`;
        this.hotelId = id;
        this.$refs.deleteModal.show();
      },
      deleteHotel(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.deleteModal.loading = true;
        if (this.hotelId) {
          HotelContentService.deleteDisabledHotel(this.hotelId)
            .then(res => {
              if (resConditionCheck(res.status)) {
                this.$refs.deleteModal.loading = false;
                this.$refs.deleteModal.toggleModal = false;
                this.items = this.items.filter(
                  item => item.id !== this.hotelId
                );
                if (this.items.length === 0 && this.currentPage !== 1) {
                  this.currentPage -= 1;
                  this.getDisabledHotelListing();
                } else {
                  this.getDisabledHotelListing();
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              console.error('Error=>',error)
              this.$refs.deleteModal.loading = false;
              this.$refs.deleteModal.toggleModal = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .statusBtn {
    border: 1px solid;
    padding: 2px 10px !important;
    font-weight: bolder;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .statusBtn:focus {
    background-color: #3f8d85!important;
    border-color: #3f8d85 !important
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-autosuggest.scss";
  </style>
  